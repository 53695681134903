import React from 'react';

function Footer() {
    return (
        <div className="footer-component d-flex align-items-center">
            vPrealfa 1.0.2 (UXN Fork) © West Telco 2021.<a href='https://www.westtelco.com.mx/aviso-de-privacidad/' rel='noreferrer' target={"_blank"} > Aviso de Privacidad.</a> Todos los derechos reservados.
        </div >
    );
}

export default Footer;